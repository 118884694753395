import * as React from 'react';

import BasicCard from './Card';

const MultipleCards = ({projects,startProject}) => {
  const cards = Object.keys(projects).map( (projectName) => {
    // if (boxObjects[labelObjects[i]][i].deletedAt == ""){    
    // console.log('consoleeeee',boxObjects[labelObjects[i]][i].deletedAt,labelObjects[i],i)    
    return(
            <BasicCard    name          = {projectName}
                          type          = {projects[projectName]['type']}
                          labels        = {projects[projectName]['labels']}
                          nImages       = {projects[projectName]['nImages']}
                          difficulty    = {projects[projectName]['difficulty']}
                          payoff        = {projects[projectName]['payoff']}
                          iri           = {projects[projectName]['iri']}
                          startProject  = {startProject}
                          />
                          )
                        }
    //}
    ); 
return(
<div className = {"cards"}> 
  {cards}
</div>
)
  }
  
  export default MultipleCards