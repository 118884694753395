import './SelectLabelButtons.css';
import ToggleButton from './ToggleButton.js'




const SelectLabelButtons = ( {labelObjects,selectedLabel,onLabelToggle,boxObjects,onClickdelete,onMouseEnter,onMouseLeave} ) => {
    // object keys needed
    const buttonArray = labelObjects.map( (labels,i) => {
                                    // if (boxObjects[labelObjects[i]][i].deletedAt == ""){    
                                    // console.log('consoleeeee',boxObjects[labelObjects[i]][i].deletedAt,labelObjects[i],i)    
                                    return(
                                            <ToggleButton label = {labelObjects[i]}
                                                          boxObjectLabel = {boxObjects[labelObjects[i]]} 
                                                          toggleLabel = {labelObjects[i].toLowerCase().trim() === selectedLabel.toLowerCase().trim()} 
                                                          toggleDelete = {false} 
                                                          onLabelToggle = {onLabelToggle}
                                                          labelObjects ={labelObjects} 
                                                          onClickdelete ={onClickdelete}
                                                          onMouseEnter= {onMouseEnter} 
                                                          onMouseLeave = {onMouseLeave}
                                                          number = {i}
                                                          />)}
                                    //}
                                    ); 
    return(
        <div className = {"ButtonLabel"}> 
            {buttonArray}
        </div>
        )
}

export default SelectLabelButtons;