//import React from "react";
import React, {useState} from "react";
import axios from "axios";
import './Login.css'
//const imageUrl = "https://i.imgur.com/fHyEMsl.jpg";
// https://www.w3schools.com/howto/tryit.asp?filename=tryhow_css_login_form for form trial

function Login() {
    const [inputs, setInputs] = useState({});

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(axios);
        axios.post( "auth", {
            email: inputs.username,
            password: inputs.password
        })
            .then(response => {
                //get token from response
                const token = response.data.token;

                //set JWT token to local
                localStorage.setItem("token", token);

                //set token to axios common header
                axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

                //redirect user to project page
                window.location.href = '/projects'

            })
            .catch(err => console.log(err));
    };


    return (
        <div className="login">
            {/* <img src="/q2labelfull.png"/> */}
            {/* <h1>Welcome</h1>
      <p>Please login to start labelling</p> */}
            <form onSubmit={handleSubmit}>
                <div className="imgcontainer">
                    <img src="/q2labelfull.png" alt="logo"/>
                </div>
                <div className="logincontainer">
                    <label>Username</label>
                    <input type="email" id="username" name="username" className="form-control" value={inputs.username || ""}
                           onChange={handleChange} required/><br/>
                    <label>Password</label>
                    <input type="password" id="password" name="password" className="form-control"
                           value={inputs.password || ""} onChange={handleChange} required/><br></br>
                    <input type="submit" value="Sign in" className="btn-primary-small"/>
                    <button type="button" className="btn-primary-small">Register</button>
                    <br/>
                    <span className="psw"><a href="#"> <i>Forgot password?</i></a></span>
                </div>
            </form>
        </div>
    );
}

export default Login
