import './SwitchPage.css'

// img as state variable such that it render upon change

// img list as input

// based on direct border is chose

const SwitchPage = ( {switchPageOnClick,switchPreviousPageOnClick,nextButton,blockSwitchPage,blockSwitchPageNext} ) => {
    console.log(nextButton)
    return(
        <div className = "previousNextButtons center">
            {/* <button className="btn btn-info btn-sm" onClick={switchPreviousPageOnClick}>Previous</button> */}
            {   blockSwitchPageNext ? 
                <button className="btn btn-dark btn-sm" onClick={switchPreviousPageOnClick}>Previous</button>
                :
                <button className="btn btn-info btn-sm" onClick={switchPreviousPageOnClick}>Previous</button>
            }
            {   blockSwitchPage ? 
                <button className="btn btn-dark btn-sm" onClick={switchPageOnClick}>{nextButton}</button>
                :
                <button className="btn btn-info btn-sm" onClick={switchPageOnClick}>{nextButton}</button>
            }
        </div>
    )
}

export default SwitchPage;