import React, {Component} from 'react';
import axios from "axios";


import './Label.css';
import SelectLabelButtons from '../components/buttons/SelectLabelButtons.js';
import ResetButton from '../components/buttons/ResetButton.js'
import SwitchPage from '../components/buttons/SwitchPage.js'


// get project specifications

const labelColors = {
    Apple: 'rgb(255,0,0)',
    Banana: 'rgb(0,0,255)',
    Orange: 'rgb(200,0,200)'
}
const labelObjects = ['Apple', 'Banana', 'Orange']


const requestOptions = {
    method: 'GET',
    headers: {'Content-Type': 'application/json'}
}
//const imageList = ['photo.jpg', 'fruit.jpg'];

// TODO
// Refactor:
// init vars
/// import all draw markers
/// import all events
/// Mouse up, down, move (inside/ outside canvas)
//// delete
//// save
// avoid usage of this.bgBoxes in function --> then rewrite everything outside of function

// TODO functionality:
// now: img function initialized > componentdidMount> fetch API of img after, this means img not displayed
// Also When click to Next and label selected > rerender and then implies reload of img function


class Label extends Component {
    ///// INITIATORS ////
    constructor(props) {

        // constructing Component class
        super(props);

        this.state = {
            loadImage: false,
            image: 'photo.jpg',
            imagePath: '/images/photo.jpg',
            // TO BE replaced by IRI?

            nextButton: "No Label",
            next: true,
            blockSwitchPage: false,
            blockSwitchPageNext: false,

            selectedLabel: "undefined",
            drag: false,
            selectedBox: "undefined",
            nClicks: 0,
            renderBgBox: true,
            //mouse : 'default'

            fgBox: {
                label: "",
                rect: {
                    startX: 0,
                    startY: 0,
                    w: 0,
                    h: 0
                },
                startClick: "",
                endClick: "",
                deletedAt: "",
                ClassKey: 0
            },
        };
        this.projectID = localStorage.getItem('projectiri').replace('/api/projects/', '')
        this.projectImages = localStorage.getItem("images").replaceAll("/api/images/", "").split(",")

        this.imgDict = {}

        // define bg Box given labels
        this.bgBoxes = {}
        labelObjects.forEach(item => this.bgBoxes[item] = {});

        // Display settings
        this.shrinkBoxhalfSize = 3;
        this.thickness = 1;
        this.crossSize = 12;
        this.maxWidthCanvas = 999;
        this.maxHeightCanvas = 666;

        // Create canvas ref and load img
        this.bgCanvasRef = React.createRef();
        this.fgCanvasRef = React.createRef();

        // img
        //this.imagePath = '/images/photo.jpg';
        //this.canvasWidth = this.maxWidthCanvas
        //this.canvasHeight = this.maxHeightCanvas
        //this.loadImage()
        this.imgID = this.projectImages[0];

        // label
        this.type = "Bounding box"
        //this.canvasToBeLoaded = false
        this.loadImage()
        //this.loadCanvas()

    }

    componentDidMount() {
        //this.loadCanvas()
        //this.loadImage()
        //this.loadCanvas()
    }

    loadCanvas = () => {
        this.canvasWidth = this.maxWidthCanvas
        this.canvasHeight = this.maxHeightCanvas
        this.bgCanvas = this.bgCanvasRef.current
        this.fgCanvas = this.fgCanvasRef.current
        this.bgContext = this.bgCanvas.getContext('2d');
        this.fgContext = this.fgCanvas.getContext('2d');

        /// Define event listeners
        // On Canvas
        this.fgCanvas.addEventListener('mousedown', this.onMouseDownCanvas, false);
        this.fgCanvas.addEventListener('mouseup', this.onMouseUpCanvas, false);
        this.fgCanvas.addEventListener('mousemove', this.onMouseMoveCanvas, false);

        // On Window
        // TODO: can we skip on canvas as can also define location of mouse and adjust behavior depending on canvas or not?
        window.addEventListener('mousemove', this.mouseMoveWindow);
        window.addEventListener('mouseup', this.mouseUpWindow);

        // Keypresses
        document.addEventListener("keydown", this.onLabelToggleKey, false);


    }

    loadImage = (next = false, first = true) => {
        // to be replaced by API call
        // get location of img, if
        let currentPos = this.projectImages.indexOf(this.imgID)
        if (next) {
            console.log('this.projectImages', this.projectImages)

            console.log('çurrentPos', currentPos, this.projectImages.length)
            // if last ob, then reset for now, later finish message
            if (currentPos == this.projectImages.length - 1) {
                this.imgID = this.projectImages[this.projectImages.length - 1]
            } else {
                console.log('nextpos', this.projectImages[currentPos + 1])
                this.imgID = this.projectImages[currentPos + 1]
            }
        } else {
            if (currentPos == 0) {
                this.imgID = this.projectImages[0]
            } else {
                this.imgID = this.projectImages[currentPos - 1]
                console.log('çurrentPos', currentPos, this.projectImages.length)
            }
        }
        this.getImage(this.imgID, first)
    }

    getImage = (id, first) => {
        axios.get(process.env.REACT_APP_API_ENDPOINT + 'api/image_with_base64/' + id)
            .then((res) => res.data)
            .then((data) => {
                this.imgsrc = data['imageBase64']

                let img = new Image();
                img.src = this.imgsrc;

                img.onload = () => {
                    // shrink to max given constant aspect ratio
                    this.scalingFactor = (img.naturalWidth / this.maxWidthCanvas) > (img.naturalHeight > this.maxHeightCanvas)
                        ? this.maxWidthCanvas / img.naturalWidth
                        : this.maxHeightCanvas / img.naturalHeight
                    // if scaling larger then 1, then image is smaller than max canvas bounds and hence not adjusted
                    this.scalingFactor = this.scalingFactor > 1 ? 1 : this.scalingFactor
                    // round, should be equal to maxWidt though, but avoid roundign errors
                    this.canvasWidth = Math.round(img.naturalWidth * this.scalingFactor)
                    this.canvasHeight = Math.round(img.naturalHeight * this.scalingFactor)
                    //If img>maxcanvas then img=maxcanvas & scale >1 and need to multiply with scale, if img<maxcanvas, then scale is 1 as canvas = img

                    this.loadCanvas()
                    if (!first) {
                        // // load bboxes, note img ID changed with load img
                        if (typeof this.imgDict["/api/images/" + this.imgID] !== "undefined") {
                            this.bgBoxes = JSON.parse(JSON.stringify(this.imgDict["/api/images/" + this.imgID]))
                            // rerender bgboxes
                            this.renderAllBoxes(this.bgBoxes)
                        }
                    }
                    this.setState({loadImage: true})
                }

                //this.setState({imageLoaded:true})

                //this.canvasToBeLoaded = true
                console.log('img loaded')
            })
    }

    ///// EVENTS /////
    onMouseDownCanvas = (event) => {
        if (this.state.selectedLabel == "undefined") {
            alert('Pls select label first!')
        } else {
            if (this.state.nextButton == "No Label") {
                // Otherwise no label is shown after click on page
                this.setState({nextButton: "Next"})
            }

            // check if mouseposition within delete box or box to shrink
            this.clickToDelete = this.checkWithinAllDeleteArea(event)
            let {
                checkShrinkBox,
                labelToShrink,
                keyToShrink,
                locationToShrink
            } = this.checkWithinAllShrinkAllBoxes(event)

            this.clicktoShrink = checkShrinkBox
            this.labelToShrink = labelToShrink
            this.keyToShrink = keyToShrink
            this.locationToShrink = locationToShrink

            if (this.clickToDelete) {
                console.log('delete')
            } else if (checkShrinkBox) {
                this.onMouseDownToShrink(event)

            } else {
                // If not detele or shrink --> start drawing normal box
                this.onMouseDownNormalBox(event)
            }
        }
    }

    onMouseDownNormalBox = (event) => {
        // TODO: use setState instead of this.state.x =x
        this.state.fgBox.label = this.state.selectedLabel
        this.state.fgBox.startClick = new Date(Date.now()).toISOString()
        this.state.fgBox.rect.startX = event.pageX - this.fgCanvas.offsetLeft; //bgCanvas needed for coordinates if fgcanvas then incorrect position for y
        this.state.fgBox.rect.startY = event.pageY - this.fgCanvas.offsetTop;
        this.state.drag = true;
        this.state.nClicks++ // only triggerd inside canvas
    }

    onMouseDownToShrink = (event) => {
        // If mouse down, then bg box are deleted and rerendered
        // bgbox label is shrinked, then bbox gets deleted at filled and new box gets immediate new stamp
        this.state.dragShrink = true

        // save all needed info
        // Do i need to haev iri ready in here or do i just make a connection later of all bboxes based on deleted at stamp?
        // DO I need to rerender boxes only when deleted at is NULL?
        let deletedAt = new Date(Date.now()).toISOString()

        this.shrinkBoxes = JSON.parse(JSON.stringify(this.bgBoxes[this.labelToShrink][this.keyToShrink]))
        this.shrinkBoxes.rectOld = JSON.parse(JSON.stringify(this.shrinkBoxes.rect))
        this.shrinkBoxes.shrinked = true

        this.bgBoxes[this.labelToShrink][this.keyToShrink].deletedAt = deletedAt
        this.shrinkBoxes.startClick = deletedAt

        // need to draw on click else disappears
        this.drawMarker(true,
            this.shrinkBoxes.rect.startX,
            this.shrinkBoxes.rect.startY,
            this.shrinkBoxes.rect.w,
            this.shrinkBoxes.rect.h,
            this.fgContext, this.labelToShrink, this.shrinkBoxes.ClassKey)

        // delete from bgBox and and restate
        // do not delete anymore, instead do not render if deletd at is not ""
        //delete this.bgBoxes[this.labelToShrink][this.keyToShrink];
        this.resetAllboxes(this.bgContext);
        this.renderAllBoxes(this.bgBoxes)
    }

    onMouseMoveCanvas = (event) => {
        if (this.state.drag) {
            this.normalBoxOnMouseMove(event)
        } else if (this.state.dragShrink) {
            this.shrinkBoxOnMouseMove(event)
        }
    }

    normalBoxOnMouseMove = (event) => {
        // Resets all boxes such that you dont have multiple lines when drawing box, implied by dragging and redrawing
        this.resetAllboxes(this.fgContext)

        this.state.fgBox.rect.w = (event.pageX - this.fgCanvas.offsetLeft) - this.state.fgBox.rect.startX;
        this.state.fgBox.rect.h = (event.pageY - this.fgCanvas.offsetTop) - this.state.fgBox.rect.startY;

        // TODO: make more clean
        // with and without border create inline opaque box and larger bob hebind implies border
        this.drawMarker(false,
            this.state.fgBox.rect.startX,
            this.state.fgBox.rect.startY,
            this.state.fgBox.rect.w,
            this.state.fgBox.rect.h,
            this.fgContext, this.state.selectedLabel)

        this.drawMarker(true,
            this.state.fgBox.rect.startX,
            this.state.fgBox.rect.startY,
            this.state.fgBox.rect.w,
            this.state.fgBox.rect.h,
            this.fgContext, this.state.selectedLabel)
    }

    shrinkBoxOnMouseMove = (event) => {
        //'upperLeft','middleLeft','downLeft','middleUpper','middleDown','upperRight','middleRight','downRight'
        if (this.locationToShrink == "upperLeft") {
            // REFACTOR: function to gen specific coordiantes and save other file?
            this.resetAllboxes(this.fgContext)

            let {x, y} = this.xyEventOffCanvas(event)

            // determine start position and change start position, then flow right automatically
            let endX = this.shrinkBoxes.rectOld.startX + this.shrinkBoxes.rectOld.w
            let endY = this.shrinkBoxes.rectOld.startY + this.shrinkBoxes.rectOld.h

            this.shrinkBoxes.rect.w = endX - this.shrinkBoxes.rect.startX
            this.shrinkBoxes.rect.startX = (x - this.fgCanvas.offsetLeft)
            this.shrinkBoxes.rect.startY = (y - this.fgCanvas.offsetTop)
            this.shrinkBoxes.rect.h = endY - this.shrinkBoxes.rect.startY

            this.drawMarker(false,
                this.shrinkBoxes.rect.startX,
                this.shrinkBoxes.rect.startY,
                this.shrinkBoxes.rect.w,
                this.shrinkBoxes.rect.h,
                this.fgContext, this.labelToShrink, this.shrinkBoxes.ClassKey)

            this.drawMarker(true,
                this.shrinkBoxes.rect.startX,
                this.shrinkBoxes.rect.startY,
                this.shrinkBoxes.rect.w,
                this.shrinkBoxes.rect.h,
                this.fgContext, this.labelToShrink, this.shrinkBoxes.ClassKey)

        } else if (this.locationToShrink == "middleLeft") {
            // h fixed, w adjustable*-1 due to start position, starting = startX+W and startY
            console.log(this.locationToShrink)
            this.resetAllboxes(this.fgContext)

            // determine start position and change start position, then flow right automatically
            let {x, y} = this.xyEventOffCanvas(event)

            this.shrinkBoxes.rect.startX = (x - this.fgCanvas.offsetLeft) //rect
            this.shrinkBoxes.rect.startY = this.shrinkBoxes.rectOld.startY

            let endX = this.shrinkBoxes.rectOld.startX + this.shrinkBoxes.rectOld.w
            //let  endY = this.shrinkBoxes.rectOld.startY + this.shrinkBoxes.rectOld.h

            this.shrinkBoxes.rect.w = endX - this.shrinkBoxes.rect.startX
            this.shrinkBoxes.rect.h = this.shrinkBoxes.rectOld.h

            this.drawMarker(false,
                this.shrinkBoxes.rect.startX,
                this.shrinkBoxes.rect.startY,
                this.shrinkBoxes.rect.w,
                this.shrinkBoxes.rect.h,
                this.fgContext, this.labelToShrink, this.shrinkBoxes.ClassKey)

            this.drawMarker(true,
                this.shrinkBoxes.rect.startX,
                this.shrinkBoxes.rect.startY,
                this.shrinkBoxes.rect.w,
                this.shrinkBoxes.rect.h,
                this.fgContext, this.labelToShrink, this.shrinkBoxes.ClassKey)

        } else if (this.locationToShrink == "downLeft") {
            // starting position = startx+w and starty
            console.log(this.locationToShrink)

            this.resetAllboxes(this.fgContext)

            let endX = this.shrinkBoxes.rectOld.startX + this.shrinkBoxes.rectOld.w
            let endY = this.shrinkBoxes.rectOld.startY //+ this.shrinkBoxes.rectOld.h

            let {x, y} = this.xyEventOffCanvas(event)

            this.shrinkBoxes.rect.w = endX - (x - this.fgCanvas.offsetLeft)
            this.shrinkBoxes.rect.h = (y - this.fgCanvas.offsetTop) - endY

            this.shrinkBoxes.rect.startX = endX - this.shrinkBoxes.rect.w
            this.shrinkBoxes.rect.startY = endY //- this.shrinkBoxes.rect.h

            this.drawMarker(false,
                this.shrinkBoxes.rect.startX,
                this.shrinkBoxes.rect.startY,
                this.shrinkBoxes.rect.w,
                this.shrinkBoxes.rect.h,
                this.fgContext, this.labelToShrink, this.shrinkBoxes.ClassKey)

            this.drawMarker(true,
                this.shrinkBoxes.rect.startX,
                this.shrinkBoxes.rect.startY,
                this.shrinkBoxes.rect.w,
                this.shrinkBoxes.rect.h,
                this.fgContext, this.labelToShrink, this.shrinkBoxes.ClassKey)
        } else if (this.locationToShrink == "middleUpper") {
            this.resetAllboxes(this.fgContext)

            let {x, y} = this.xyEventOffCanvas(event)

            // determine start position and change start position, then flow right automatically
            this.shrinkBoxes.rect.startX = this.shrinkBoxes.rectOld.startX //rect
            this.shrinkBoxes.rect.w = this.shrinkBoxes.rectOld.w

            let endY = this.shrinkBoxes.rectOld.startY + this.shrinkBoxes.rectOld.h
            // dynamic
            this.shrinkBoxes.rect.h = endY - (y - this.fgCanvas.offsetTop)
            this.shrinkBoxes.rect.startY = endY - this.shrinkBoxes.rect.h// DYNAMCI!

            this.drawMarker(false,
                this.shrinkBoxes.rect.startX,
                this.shrinkBoxes.rect.startY,
                this.shrinkBoxes.rect.w,
                this.shrinkBoxes.rect.h,
                this.fgContext, this.labelToShrink, this.shrinkBoxes.ClassKey)

            this.drawMarker(true,
                this.shrinkBoxes.rect.startX,
                this.shrinkBoxes.rect.startY,
                this.shrinkBoxes.rect.w,
                this.shrinkBoxes.rect.h,
                this.fgContext, this.labelToShrink, this.shrinkBoxes.ClassKey)

        } else if (this.locationToShrink == "middleDown") {
            // fix w, start = startX,startY, h, adjustable
            this.resetAllboxes(this.fgContext)

            let {x, y} = this.xyEventOffCanvas(event)

            // determine start position and change start position, then flow right automatically
            this.shrinkBoxes.rect.startX = this.shrinkBoxes.rectOld.startX //rect
            this.shrinkBoxes.rect.w = this.shrinkBoxes.rectOld.w
            this.shrinkBoxes.rect.startY = this.shrinkBoxes.rectOld.startY// DYNAMCI!

            // dynamic
            this.shrinkBoxes.rect.h = (y - this.fgCanvas.offsetTop) - this.shrinkBoxes.rect.startY
            let endY = this.shrinkBoxes.rectOld.startY + this.shrinkBoxes.rect.h

            this.drawMarker(false,
                this.shrinkBoxes.rect.startX,
                this.shrinkBoxes.rect.startY,
                this.shrinkBoxes.rect.w,
                this.shrinkBoxes.rect.h,
                this.fgContext, this.labelToShrink, this.shrinkBoxes.ClassKey)

            this.drawMarker(true,
                this.shrinkBoxes.rect.startX,
                this.shrinkBoxes.rect.startY,
                this.shrinkBoxes.rect.w,
                this.shrinkBoxes.rect.h,
                this.fgContext, this.labelToShrink, this.shrinkBoxes.ClassKey)
            console.log(this.locationToShrink)
        } else if (this.locationToShrink == "upperRight") {
            // starting position=  startx and starty+h
            let {x, y} = this.xyEventOffCanvas(event)

            this.resetAllboxes(this.fgContext)

            // determine start position and change start position, then flow right automatically
            this.shrinkBoxes.rect.startX = this.shrinkBoxes.rectOld.startX
            this.shrinkBoxes.rect.startY = (y - this.fgCanvas.offsetTop)

            //let  endX = this.shrinkBoxes.rectOld.startX
            let endY = this.shrinkBoxes.rectOld.startY + this.shrinkBoxes.rectOld.h

            this.shrinkBoxes.rect.w = (x - this.fgCanvas.offsetLeft) - this.shrinkBoxes.rect.startX
            this.shrinkBoxes.rect.h = endY - this.shrinkBoxes.rect.startY

            // REFACTOR: this can be redefined to bool,x,y,w,h,cxt,label
            this.drawMarker(false,
                this.shrinkBoxes.rect.startX,
                this.shrinkBoxes.rect.startY,
                this.shrinkBoxes.rect.w,
                this.shrinkBoxes.rect.h,
                this.fgContext, this.labelToShrink, this.shrinkBoxes.ClassKey)

            this.drawMarker(true,
                this.shrinkBoxes.rect.startX,
                this.shrinkBoxes.rect.startY,
                this.shrinkBoxes.rect.w,
                this.shrinkBoxes.rect.h,
                this.fgContext, this.labelToShrink, this.shrinkBoxes.ClassKey)

        } else if (this.locationToShrink == "middleRight") {
            // // start = startX,startY, h fixed, w adjustable
            this.resetAllboxes(this.fgContext)

            let {x, y} = this.xyEventOffCanvas(event)
            // determine start position and change start position, then flow right automatically
            this.shrinkBoxes.rect.startX = this.shrinkBoxes.rectOld.startX //rect
            this.shrinkBoxes.rect.startY = this.shrinkBoxes.rectOld.startY

            this.shrinkBoxes.rect.w = (x - this.fgCanvas.offsetLeft) - this.shrinkBoxes.rect.startX
            this.shrinkBoxes.rect.h = this.shrinkBoxes.rectOld.h

            this.drawMarker(false,
                this.shrinkBoxes.rect.startX,
                this.shrinkBoxes.rect.startY,
                this.shrinkBoxes.rect.w,
                this.shrinkBoxes.rect.h,
                this.fgContext, this.labelToShrink, this.shrinkBoxes.ClassKey)

            this.drawMarker(true,
                this.shrinkBoxes.rect.startX,
                this.shrinkBoxes.rect.startY,
                this.shrinkBoxes.rect.w,
                this.shrinkBoxes.rect.h,
                this.fgContext, this.labelToShrink, this.shrinkBoxes.ClassKey)

        } else if (this.locationToShrink == "downRight") {
            // starting position = startx and start y
            console.log(this.locationToShrink)

            this.resetAllboxes(this.fgContext)

            let {x, y} = this.xyEventOffCanvas(event)

            // determine start position and change start position, then flow right automatically
            this.shrinkBoxes.rect.startX = this.shrinkBoxes.rectOld.startX
            this.shrinkBoxes.rect.startY = this.shrinkBoxes.rectOld.startY

            this.shrinkBoxes.rect.w = (x - this.fgCanvas.offsetLeft) - this.shrinkBoxes.rect.startX
            this.shrinkBoxes.rect.h = (y - this.fgCanvas.offsetTop) - this.shrinkBoxes.rect.startY

            // REFACTOR: this can be redefined to bool,x,y,w,h,cxt,label
            this.drawMarker(false,
                this.shrinkBoxes.rect.startX,
                this.shrinkBoxes.rect.startY,
                this.shrinkBoxes.rect.w,
                this.shrinkBoxes.rect.h,
                this.fgContext, this.labelToShrink, this.shrinkBoxes.ClassKey)

            this.drawMarker(true,
                this.shrinkBoxes.rect.startX,
                this.shrinkBoxes.rect.startY,
                this.shrinkBoxes.rect.w,
                this.shrinkBoxes.rect.h,
                this.fgContext, this.labelToShrink, this.shrinkBoxes.ClassKey)
        }
    }

    xyEventOffCanvas = (event) => {
        let x = event.pageX
        let y = event.pageY

        x = Math.max(x, this.fgCanvas.offsetLeft)
        x = Math.min(x, this.fgCanvas.offsetLeft + Number(this.canvasWidth))

        y = Math.max(y, this.fgCanvas.offsetTop)
        y = Math.min(y, this.fgCanvas.offsetTop + Number(this.canvasHeight))
        return ({x, y})
    }

    onMouseUpCanvas = (event) => {
        // coordinates are defined by move, on release need to store in background
        if (this.clickToDelete) {
            console.log('clicked to delete')
        } else if (this.clicktoShrink) {
            this.state.dragShrink = false
            // startclick defined on mouse down equals deleted at at previous oen
            this.shrinkBoxes.endClick = new Date(Date.now()).toISOString()
            this.shrinkBoxes.deletedAt = ""
            // save in object to rerender when delete and store
            // here, make new box instead of shrinking? But then numbering hsould be equal?
            // if (typeof this.bgBoxes[this.labelToShrink][this.keyToShrink].priorBox == 'undefined'){
            //   this.shrinkBoxes.ClassKey = this.keyToShrink
            // } else {
            this.shrinkBoxes.ClassKey = this.bgBoxes[this.labelToShrink][this.keyToShrink].ClassKey
            this.shrinkBoxes.priorBox = this.labelToShrink + "_" + this.shrinkBoxes.ClassKey // To link IRI
            console.log('bgboxes', this.bgBoxes, this.keyToShrink)
            // }

            let newClassKey = this.getGewClassKey(this.bgBoxes[this.labelToShrink])
            // this.bgBoxes[this.labelToShrink][this.keyToShrink] = JSON.parse(JSON.stringify(this.shrinkBoxes));
            this.bgBoxes[this.labelToShrink][newClassKey] = JSON.parse(JSON.stringify(this.shrinkBoxes));
            console.log('bgboxes shrink', this.bgBoxes)
            // reset fgContext as need to draw again
            this.resetAllboxes(this.fgContext);
            this.renderAllBoxes(this.bgBoxes)

        } else {
            this.state.drag = false;
            this.state.fgBox.endClick = new Date(Date.now()).toISOString()
            //this.state.fgBox.ClassKey = this.keyToShrink

            // draw on background instead of foreground
            this.drawMarker(true,
                this.state.fgBox.rect.startX,
                this.state.fgBox.rect.startY,
                this.state.fgBox.rect.w,
                this.state.fgBox.rect.h,
                this.bgContext, this.state.selectedLabel)

            // save in object to rerender when delete and store
            this.saveFgToBgBoxes();

            // reset fgContext as need to draw again
            this.resetAllboxes(this.fgContext);
        }
    }

    mouseMoveWindow = (event) => {
        // can be copied from mousemove canvas, but then with xyExceedsCanvas function to return capped x,y
        if (this.state.drag) {
            this.resetAllboxes(this.fgContext)

            // EDGES
            if (event.pageY > this.fgCanvas.offsetTop + Number(this.canvasHeight)) {
                this.state.fgBox.rect.w = (event.pageX - this.fgCanvas.offsetLeft) - this.state.fgBox.rect.startX
            }
            if (event.pageY < this.fgCanvas.offsetTop) {
                this.state.fgBox.rect.w = (event.pageX - this.fgCanvas.offsetLeft) - this.state.fgBox.rect.startX
                //this.state.fgBox.rect.h = this.fgCanvas.offsetTop - this.state.fgBox.rect.startY
                // the heigth doesnt need to adjust as defined on exit of canvas automatically
            }
            if (event.pageX > this.fgCanvas.offsetLeft + Number(this.canvasWidth)) {
                this.state.fgBox.rect.h = (event.pageY - this.fgCanvas.offsetTop) - this.state.fgBox.rect.startY
                //this.state.fgBox.rect.w = this.fgCanvas.offsetLeft + this.fgcanvas.canvasWidth - this.state.fgBox.rect.startX
            }

            if (event.pageX < this.fgCanvas.offsetLeft) {
                this.state.fgBox.rect.h = (event.pageY - this.fgCanvas.offsetTop) - this.state.fgBox.rect.startY
                //this.state.fgBox.rect.w = this.fgCanvas.offsetLeft - this.state.fgBox.rect.startX
                // the width doesnt need to adjust as defined on exit of canvas automatically
            }

            // CORNERS
            if ((event.pageY < this.fgCanvas.offsetTop) & (event.pageX < this.fgCanvas.offsetLeft)) {
                this.state.fgBox.rect.h = -this.state.fgBox.rect.startY
                this.state.fgBox.rect.w = -this.state.fgBox.rect.startX
            }
            if ((event.pageY < this.fgCanvas.offsetTop) & (event.pageX > this.fgCanvas.offsetLeft + Number(this.canvasWidth))) {
                this.state.fgBox.rect.h = -this.state.fgBox.rect.startY
                this.state.fgBox.rect.w = this.canvasWidth - this.state.fgBox.rect.startX
            }

            if ((event.pageY > this.fgCanvas.offsetTop + Number(this.canvasHeight)) & (event.pageX < this.fgCanvas.offsetLeft)) {
                this.state.fgBox.rect.h = this.canvasHeight - this.state.fgBox.rect.startY
                this.state.fgBox.rect.w = -this.state.fgBox.rect.startX
                console.log('in here')
            }
            if ((event.pageY > this.fgCanvas.offsetTop + Number(this.canvasHeight)) & (event.pageX > this.fgCanvas.offsetLeft + Number(this.canvasWidth))) {
                this.state.fgBox.rect.h = this.canvasHeight - this.state.fgBox.rect.startY
                this.state.fgBox.rect.w = this.canvasWidth - this.state.fgBox.rect.startX
            }

            this.drawMarker(false,
                this.state.fgBox.rect.startX,
                this.state.fgBox.rect.startY,
                this.state.fgBox.rect.w,
                this.state.fgBox.rect.h,
                this.fgContext, this.state.selectedLabel)


            this.drawMarker(true,
                this.state.fgBox.rect.startX,
                this.state.fgBox.rect.startY,
                this.state.fgBox.rect.w,
                this.state.fgBox.rect.h,
                this.fgContext, this.state.selectedLabel)

        } else if (this.state.dragShrink) {
            this.shrinkBoxOnMouseMove(event)
        }
    }


    mouseUpWindow = (event) => {
        if (this.state.drag) {
            this.state.drag = false;
            this.drawMarker(true,
                this.state.fgBox.rect.startX,
                this.state.fgBox.rect.startY,
                this.state.fgBox.rect.w,
                this.state.fgBox.rect.h,
                this.bgContext, this.state.selectedLabel)

            this.state.fgBox.endClick = new Date(Date.now()).toISOString()
            this.saveFgToBgBoxes()
            this.resetAllboxes(this.fgContext);

        } else if (this.state.dragShrink) {
            this.state.dragShrink = false
            this.bgBoxes[this.labelToShrink][this.keyToShrink] = JSON.parse(JSON.stringify(this.shrinkBoxes));
            this.setState({renderBgBox: true})
        }
    }

    onClickdelete = (event) => {
        let boxToDelete = event.target.innerHTML.replace("- ", "");
        let labelToDelete = event.target.name;
        this.resetAllboxes(this.fgContext);
        this.onClickDeletebgBox(labelToDelete, boxToDelete)
    }

    onClickDeletebgBox = (labelToDelete, boxToDelete) => {
        //delete this.bgBoxes[labelToDelete][boxToDelete];
        this.bgBoxes[labelToDelete][boxToDelete].deletedAt = new Date(Date.now()).toISOString()
        this.resetAllboxes(this.bgContext);
        this.renderAllBoxes(this.bgBoxes);
        this.setState({rerenderBox: true})
    }


    postLabels = (label, key) => {
        // POST request api/label: type, image,user,bbox=[],classification,
        // Loop over bboxes
        // only if the label doesn't exist yet note per key 1 label iri. if there exists
        // if (typeof this.postBoxes[label][key] !== "undefined"){
        //   console.log('not UNDEFIEND',this.postBoxes[label][key][Object.keys(this.postBoxes[label][key])[0]] )
        // if (typeof this.postBoxes[label][key][Object.keys(this.postBoxes[label][key])[0]].labelIRI === "undefined") {
        const requestOptions = {
            method: 'POST',
            data: JSON.stringify({
                type: this.type,
                image: "api/images/" + this.imgID,
                // WAAROM EERST label posten, dan pas bbox?
                bBoxes: [],
                classification: this.bgBoxes[label][key].label
            })
        };
        //console.log("requestOptions", requestOptions)
        return axios(process.env.REACT_APP_API_ENDPOINT + 'api/labels', requestOptions)
            .then((res) => res.data)
            .then((data) => {
                console.log('POST LABEL', data.id)
                return data.id
            })
            .catch(err => console.log(err))
        //.then(data => console.log('post labels id', data.id))
    } // ELSE: NOTHING, as the labels will not change! Only added =POST
    // }}

    boxRequest = (x, y, w, h, labeliri, start, end, deleted, type, bbIRI = "") => {
        if (bbIRI !== "") {
            bbIRI = "/" + bbIRI
        }
        if (deleted == "") {
            deleted = null
        }

        const requestOptions = {
            method: type,
            headers: { 'Content-Type': 'application/json' },
            data: JSON.stringify({
                x: x,
                y: y,
                width: w,
                height: h,
                label: labeliri,
                startClick: start,
                endClick: end,
                deletedAt: deleted
            })
        };
        console.log("requestOptions", requestOptions)
        return axios(process.env.REACT_APP_API_ENDPOINT + 'api/b_boxes' + bbIRI, requestOptions)
            .then((res) => res.data)
            .then((data) => {
                console.log('PUT/POST,bbiri,labiri,dataid', type, bbIRI, labeliri, data.id)
                return data.id
            })
            .catch(err => console.log('error', err))
        //.then(data => console.log('post labels id', data.id))

    }

    // postBBoxes = (x,y,w,h,labeliri,start,end,deleted,label,key,subkey) => {
    //   // POST request api/label: type, image,user,bbox=[],classification,
    //   // Loop over bboxes
    //   // only post when bbIRI not defined
    //   // PUT when deletedat != ""
    //   if (typeof this.postBoxes[label][key][subkey].bbIRI === "undefined") {
    //     this.boxRequest(x,y,w,h,labeliri,start,end,deleted,"POST")
    //   //  if (deleted== ""){
    //   //    deleted = null
    //   //  }

    //   //  const requestOptions = {
    //   //    method: 'POST',
    //   //    headers: { 'Content-Type': 'application/json' },
    //   //    body: JSON.stringify({
    //   //        x: x,
    //   //        y: y,
    //   //        width: w,
    //   //        height: h,
    //   //        label: labeliri,
    //   //        startClick: start,
    //   //        endClick: end,
    //   //        deletedAt: deleted
    //   //    })
    //   //  };
    //   //  //console.log("requestOptions", requestOptions)
    //   //  return axios.get(process.env.REACT_APP_API_ENDPOINT +'api/b_boxes', requestOptions)
    //   //    .then((response) => {
    //   //       //console.log('post succes',response)
    //   //        return response.data.then(data => {
    //   //          return data.id
    //   //         })})
    //   //      .catch(err => console.log('error',err))
    //   //    //.then(data => console.log('post labels id', data.id))
    //   } else if (this.postBoxes[label][key][subkey].deletedAt !== "") {
    //     // if bbIRI exists, and now deletedAy is filled (also triggered by shrinking) then update the data
    //     this.boxRequest(x,y,w,h,labeliri,start,end,deleted,"PUT")

    //   }
    // }

    createPostBoxes = (boxDict) => {
        this.postBoxes = {}
        //labelObjects
        Object.keys(boxDict).forEach(label => {
            this.postBoxes[label] = {}
        })
        // ClassKey is a unique identifier checking boxes which are to be modified (but classky then remains the same)
        Object.keys(boxDict).forEach(label => Object.keys(boxDict[label]).forEach(key => {
            this.postBoxes[label][boxDict[label][key].ClassKey] = {}
        }))
        Object.keys(boxDict).forEach(label => Object.keys(boxDict[label]).forEach(key => {
            this.postBoxes[label][boxDict[label][key].ClassKey][key] = boxDict[label][key]
        }))
    }

    switchPreviousPageOnClick = () => {
        this.switchPageOnClick(false)
    }

    switchPageOnClick = (next) => {
        // only allow if button not blocked
        if (this.state.blockSwitchPage == false) {
            let timeSwitchPage = new Date(Date.now()).toISOString()
            let count = 0
            let currentIMG = this.imgID

            // Count if empty, then 0 and "No label" needs to be supplied
            Object.values(this.bgBoxes).forEach(val => {
                count += Object.values(val).length
                console.log('length', count)
            })

            if (count == 0) {
                // add label objects in case there is no onbject in image as needed in create postboxes
                this.bgBoxes['NoLabel'] = {
                    "1": {
                        "ClassKey": "1",
                        "label": "NoLabel",
                        "rect": {
                            "startX": 0,
                            "startY": 0,
                            "w": 0,
                            "h": 0,
                        },
                        "startClick": timeSwitchPage,
                        "endClick": timeSwitchPage,
                        "deletedAt": "",
                    }
                }
            }
            this.imgDict["/api/images/" + currentIMG] = JSON.parse(JSON.stringify(this.bgBoxes))

            // Post the boxes
            this.createPostBoxes(this.imgDict["/api/images/" + currentIMG])// in here only create boxes when irir is not specified for bgBoxes?
            // this.createPUTpoxes? using irir's HOW DOES postboxes link back
            Object.keys(this.postBoxes).forEach(label => Object.keys(this.postBoxes[label]).forEach(key => {
                // only POST label if the label + key exists and it is not define before, never PUT
                if (typeof this.postBoxes[label][key] !== "undefined") {
                    console.log('not UNDEFIEND', this.postBoxes[label][key][Object.keys(this.postBoxes[label][key])[0]])
                    if (typeof this.postBoxes[label][key][Object.keys(this.postBoxes[label][key])[0]].labelIRI === "undefined") {
                        this.postLabels(label, key).then(iri => {
                            console.log('IRIRIRIIR', iri)
                            Object.keys(this.postBoxes[label][key]).forEach(subkey => {

                                    let bbox = this.postBoxes[label][key][subkey]
                                    this.postBoxes[label][key][subkey].labelIRI = "api/labels/" + iri

                                    // rescale to original size
                                    let x = Math.round(bbox.rect.startX / this.scalingFactor)
                                    let y = Math.round(bbox.rect.startY / this.scalingFactor)
                                    let w = Math.round(bbox.rect.w / this.scalingFactor)
                                    let h = Math.round(bbox.rect.h / this.scalingFactor)

                                    // if for first time next then post them if bbirir doesnt exist, copy paste this for put but then not posting labels for which label IRI  doesnt exist yet
                                    if (typeof this.postBoxes[label][key][subkey].bbIRI === "undefined") {
                                        this.boxRequest(x, y, w, h, bbox.labelIRI, bbox.startClick, bbox.endClick, bbox.deletedAt, "POST").then(bbiri => {
                                            this.postBoxes[label][key][subkey].bbIRI = 'api/b_boxes/' + bbiri

                                            // store back to save for use later
                                            console.log('this.imgDict', this.imgDict["/api/images/" + currentIMG])
                                            this.imgDict["/api/images/" + currentIMG][label][subkey].labelIRI = "api/labels/" + iri
                                            this.imgDict["/api/images/" + currentIMG][label][subkey].bbIRI = this.postBoxes[label][key][subkey].bbIRI
                                        })
                                    }

                                }
                            )
                        })
                    } else {
                        Object.keys(this.postBoxes[label][key]).forEach(subkey => {
                            // for all this.postBoxes where a label IRI is defined ( implied by if statement) either POST: if no bbIRI, PUT if bbIRI and deletedAt is not ""
                            let bbox = this.postBoxes[label][key][subkey]
                            // rescale to original size
                            let x = Math.round(bbox.rect.startX / this.scalingFactor)
                            let y = Math.round(bbox.rect.startY / this.scalingFactor)
                            let w = Math.round(bbox.rect.w / this.scalingFactor)
                            let h = Math.round(bbox.rect.h / this.scalingFactor)

                            if (typeof this.postBoxes[label][key][subkey].bbIRI === "undefined") {
                                this.boxRequest(x, y, w, h, bbox.labelIRI, bbox.startClick, bbox.endClick, bbox.deletedAt, "POST").then(bbiri => {
                                    this.postBoxes[label][key][subkey].bbIRI = 'api/b_boxes/' + bbiri

                                    // store back to save for use later
                                    this.imgDict["/api/images/" + this.imgID][label][subkey].labelIRI = bbox.labelIRI
                                    this.imgDict["/api/images/" + this.imgID][label][subkey].bbIRI = this.postBoxes[label][key][subkey].bbIRI
                                })

                            } else if (this.postBoxes[label][key][subkey].deletedAt !== "") {
                                let bbox = this.postBoxes[label][key][subkey]

                                // rescale to original size
                                let x = Math.round(bbox.rect.startX / this.scalingFactor)
                                let y = Math.round(bbox.rect.startY / this.scalingFactor)
                                let w = Math.round(bbox.rect.w / this.scalingFactor)
                                let h = Math.round(bbox.rect.h / this.scalingFactor)
                                // if bbIRI exists, and now deletedAy is filled (also triggered by shrinking) then update the data
                                console.log('before put', bbox)
                                // to connect PUT
                                let bbIRI = this.postBoxes[label][key][subkey].bbIRI.replace("api/b_boxes/", "")
                                this.boxRequest(x, y, w, h, bbox.labelIRI, bbox.startClick, bbox.endClick, bbox.deletedAt, "PUT", bbIRI)
                            }
                        })
                    }
                }


                // this.postBBoxes(x,y,w,h,
                //                 bbox.labelIRI,bbox.startClick,bbox.endClick,bbox.deletedAt,label,key,subkey).then(bbiri => {
                //                   this.postBoxes[label][key][subkey].bbIRI = 'api/b_boxes' + bbiri

                //                   // store back to save for use later
                //                   this.imgDict["/api/images/"+ this.imgID][label][subkey].labelIRI = "api/labels/"  + iri
                //                   this.imgDict["/api/images/"+ this.imgID][label][subkey].bbIRI = this.postBoxes[label][key][subkey].bbIRI
                //                 })
            }))


            console.log('fdasfdasfdasgbadfg', this.postBoxes)
            // labelirir and bb iri needs to be supported in here?
            // now no link existing labels iri's and bboxes
            console.log('mgdict', this.imgDict)

            // reset bbox + canvas
            this.resetBackgrounds()
            this.resetAllboxes(this.bgContext)
            this.resetAllboxes(this.fgContext)

            // Load new image: if next = true then next image loaded else previous
            this.loadImage(next, false)

            // change the button back + freeze for some amount of time

            this.setState({nextButton: "No Label"})
            if (next) {
                this.setState({blockSwitchPage: true})
                setTimeout(() => {
                    this.setState({blockSwitchPage: false})
                }, 1000)
            } else {
                this.setState({blockSwitchPageNext: true})
                setTimeout(() => {
                    this.setState({blockSwitchPageNext: false})
                }, 1000)
            }

        }
    }

    ///// FUNCTIONALITIES /////
    drawMarker = (border, startX, startY, w, h, cxt, label, key = "undefined", hover = false) => {
        if (border) {
            let color = labelColors[label]
            if (key == "undefined") {
                key = this.getNewClassKey();
            }
            this.drawOutside(startX, startY, w, h, cxt, color, label, key)
        } else {
            this.drawInside(startX, startY, w, h, cxt, label, hover)
        }
    }

    drawOutside = (startX, startY, w, h, cxt, color, label, key) => {
        cxt.beginPath();
        cxt.rect(startX - this.thickness, startY - this.thickness, w + this.thickness * 2, h + this.thickness * 2);
        cxt.strokeStyle = color
        cxt.font = "bold 12px Calibri Light";
        cxt.fillStyle = color
        let text = key + ". " + label;
        let labelStartX = Math.min(startX, startX + w)
        let labelStartY = Math.min(startY, startY + h)
        cxt.fillRect(labelStartX - this.thickness, labelStartY - this.thickness, cxt.measureText(text).width + 3, -13)
        cxt.fillStyle = "white"
        cxt.fillText(text, labelStartX, labelStartY - 2);
        cxt.stroke();

        this.deleteButton(cxt, startX, startY, w, color);
        this.drawShrinkBox(cxt, startX, startY, w, h, color);
    }

    drawInside = (startX, startY, w, h, cxt, label, hover) => {
        if (hover) {
            // if hover on button gets colored in
            let color = labelColors[label]
            color = color.replace(")", ",0.5)")
            color = color.replace("rgb", "rgba")
            cxt.fillStyle = color
        } else {
            cxt.fillStyle = "rgba(255,255,255,0.15)";
        }
        cxt.fillRect(startX, startY, w, h);
    }

    renderAllBoxes = (bgBoxCopy) => {
        Object.keys(bgBoxCopy).forEach(label => Object.keys(bgBoxCopy[label]).forEach(key => {
                    if (bgBoxCopy[label][key].deletedAt == "") {
                        this.drawMarker(true,
                            bgBoxCopy[label][key].rect.startX,
                            bgBoxCopy[label][key].rect.startY,
                            bgBoxCopy[label][key].rect.w,
                            bgBoxCopy[label][key].rect.h,
                            this.bgContext, label, bgBoxCopy[label][key].ClassKey)
                    }
                }
            )
        )
    }

    drawShrinkBox = (ctx, startX, startY, w, h, color) => {
        // draw all srinkboxes
        let boxSize = this.shrinkBoxhalfSize * 2;

        //Y
        let upperStartBoxY = startY - this.shrinkBoxhalfSize;
        let middleStartBoxY = startY - this.shrinkBoxhalfSize + 0.5 * h;
        let downStartBoxY = startY - this.shrinkBoxhalfSize + h;

        // X
        let leftStartBoxX = startX - this.shrinkBoxhalfSize;
        let middleStartBoxX = startX - this.shrinkBoxhalfSize + 0.5 * w;
        let RightStartBoxX = startX - this.shrinkBoxhalfSize + w;

        // right: this will coincide with  delete button, better this way or box fully outside.
        // if defined after check delete then delete will go first

        let allShrinkBoxes = {
            'upperLeft': [leftStartBoxX, upperStartBoxY],
            'middleLeft': [leftStartBoxX, middleStartBoxY],
            'downLeft': [leftStartBoxX, downStartBoxY],
            'middleUpper': [middleStartBoxX, upperStartBoxY],
            'middleDown': [middleStartBoxX, downStartBoxY],
            'upperRight': [RightStartBoxX, upperStartBoxY],
            'middleRight': [RightStartBoxX, middleStartBoxY],
            'downRight': [RightStartBoxX, downStartBoxY]
        }
        // Loop over all boxes and shrinkBox [x,y]
        for (const [key, value] of Object.entries(allShrinkBoxes)) {
            this.shrinkBox(ctx, value[0], value[1], boxSize, color)
        }

    }

    shrinkBox = (ctx, startBoxX, startBoxY, boxSize, color) => {
        // draw individual shrinkbox
        // maybe outside stroke in future?
        ctx.fillStyle = color
        ctx.fillRect(startBoxX, startBoxY, boxSize, boxSize)
    }

    deleteButton = (ctx, startX, startY, w, color) => {
        // repaeting code, need to do differently, maybe simply replace

        let rightTopCornerX = startX + w
        let rightTopCornerY = startY

        let leftTopCornerX = startX + w - this.crossSize
        let leftTopCornerY = startY

        let leftBottomCornerX = startX + w - this.crossSize
        let leftBottomCornerY = startY + this.crossSize

        let rightBottomCornerX = startX + w
        let rightBottomCornerY = startY + this.crossSize

        ctx.fillStyle = color
        ctx.fillRect(leftTopCornerX, leftTopCornerY, this.crossSize, this.crossSize)

        ctx.beginPath()
        ctx.strokeStyle = "black"
        ctx.moveTo(leftTopCornerX, leftTopCornerY);
        ctx.lineTo(rightBottomCornerX, rightBottomCornerY);
        ctx.moveTo(leftBottomCornerX, leftBottomCornerY);
        ctx.lineTo(rightTopCornerX, rightTopCornerY);
        ctx.rect(leftTopCornerX, leftTopCornerY, this.crossSize, this.crossSize) // positive w,h shoudl draw angle from startpositions
        ctx.stroke();
    }

    resetAllboxes = (cxt) => {
        cxt.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
    }

    getNewClassKey = () => {
        let newClassKey;
        let minClassKey;
        if (Object.keys(this.bgBoxes[this.state.selectedLabel]).length == 0) {
            newClassKey = 1;
        } else {
            minClassKey = Math.max(...Object.keys(this.bgBoxes[this.state.selectedLabel]));
            newClassKey = minClassKey + 1;
        }
        return (newClassKey)
    }

    resetCanvasObject = () => {
        let bgObjectReset = JSON.parse(JSON.stringify(labelObjects.reduce((a, key) => Object.assign(a, {[key]: {}}), {})));
        return (bgObjectReset)
    }

    resetBackgrounds = () => {
        this.state.fgBox = {
            label: "",
            rect: {
                startX: 0,
                startY: 0,
                w: 0,
                h: 0
            },
            startClick: "",
            endClick: "",
            deletedAt: ""
        }
        this.bgBoxes = this.resetCanvasObject()
    }

    getGewClassKey = (boxObject) => {
        let newClassKey;
        let minClassKey;
        if (Object.keys(boxObject).length == 0) {
            newClassKey = 1;
        } else {
            minClassKey = Math.max(...Object.keys(boxObject));
            newClassKey = minClassKey + 1;
        }
        return (newClassKey)
    }

    saveFgToBgBoxes = () => {
        // // fired on mouse up
        // let newClassKey;
        // let minClassKey;
        // if (Object.keys(this.bgBoxes[this.state.selectedLabel]).length == 0){
        //   newClassKey = 1;
        // } else {
        //   minClassKey = Math.max(... Object.keys(this.bgBoxes[this.state.selectedLabel]));
        //   newClassKey = minClassKey+1;
        // }
        // // append box
        // this.bgBoxes[this.state.selectedLabel][newClassKey] = JSON.parse(JSON.stringify(this.state.fgBox));
        let newClassKey = this.getGewClassKey(this.bgBoxes[this.state.selectedLabel]);
        this.bgBoxes[this.state.selectedLabel][newClassKey] = JSON.parse(JSON.stringify(this.state.fgBox))
        this.bgBoxes[this.state.selectedLabel][newClassKey].ClassKey = newClassKey.toString()
        this.setState({rerenderBox: true})
    }

    ///// EVENT CHECKS: DELETE/SHRINK /////
    checkWithinAllDeleteArea = (event) => {
        console.log('check whetehr in delete area', this.bgBoxes)
        let checkToDelete = false
        Object.keys(this.bgBoxes).forEach(label => Object.keys(this.bgBoxes[label]).forEach(key => {
                    if (this.checkWithinDeleteArea(event, this.bgBoxes[label][key].rect['startX'], this.bgBoxes[label][key].rect['startY'], this.bgBoxes[label][key].rect['w'])) {
                        // delete box from boxes and rereder again
                        // for below, just label and box needed. modify onClickdeltet to be function of label and key. Not even deltee box areas needed. But how are we going to find label and key?
                        // create delet box with label and key anyway and get from that. alternative to look in al boxes but seems less efficient
                        console.log('onclickdelete(label,key)', label, key)
                        this.onClickDeletebgBox(label, key);
                        checkToDelete = true
                    }
                }
            )
        )
        return (checkToDelete)
    }

    checkWithinDeleteArea = (event, startX, startY, w) => {
        // execute on mousemoveCanvas, check this for all boxes, store in dict somewhere, start with 0,0,0
        let rightTopCornerX = startX + w
        let rightTopCornerY = startY

        let leftTopCornerX = startX + w - this.crossSize
        let rightBottomCornerY = startY + this.crossSize

        let coordinateX = event.pageX - this.fgCanvas.offsetLeft
        let coordinateY = event.pageY - this.fgCanvas.offsetTop
        return ((coordinateX > leftTopCornerX) & (coordinateX < rightTopCornerX) & (coordinateY > rightTopCornerY) & (coordinateY < rightBottomCornerY))
    }

    checkWithinAllShrinkAllBoxes = (event) => {
        // loop over all boxes to get startpositions and checkWithinAllshrink boxes to see what bb needs to be changed
        // note: needs to be deleted from bgBox as well!
        let checkShrinkBox = false
        let labelToShrink = "undefined"
        let keyToShrink = "undefined"
        let locationToShrink = "undefined"
        // for all labels in bgBoxes, for all newClassKeys get this.state.bgBoxes[label][key]['rect']['startX']
        Object.keys(this.bgBoxes).forEach(label => Object.keys(this.bgBoxes[label]).forEach(key => {
                    let startX = this.bgBoxes[label][key]['rect']['startX']
                    let startY = this.bgBoxes[label][key]['rect']['startY']
                    let w = this.bgBoxes[label][key]['rect']['w']
                    let h = this.bgBoxes[label][key]['rect']['h']
                    let {checkToShrink, shrinkBoxLocation} = this.checkWithinAllShrinkBoxes(event, startX, startY, w, h)
                    if (checkToShrink) {
                        checkShrinkBox = true
                        labelToShrink = label
                        keyToShrink = key
                        locationToShrink = shrinkBoxLocation
                    }
                }
            )
        )
        return {checkShrinkBox, labelToShrink, keyToShrink, locationToShrink}

    }

    checkWithinAllShrinkBoxes = (event, startX, startY, w, h) => {
        let checkToShrink = false
        let shrinkBoxLocation = "undefined"
        let boxSize = this.shrinkBoxhalfSize * 2;

        //Y
        let upperStartBoxY = startY - this.shrinkBoxhalfSize;
        let middleStartBoxY = startY - this.shrinkBoxhalfSize + 0.5 * h;
        let downStartBoxY = startY - this.shrinkBoxhalfSize + h;

        // X
        let leftStartBoxX = startX - this.shrinkBoxhalfSize;
        let middleStartBoxX = startX - this.shrinkBoxhalfSize + 0.5 * w;
        let RightStartBoxX = startX - this.shrinkBoxhalfSize + w;

        let allShrinkBoxes = {
            'upperLeft': [leftStartBoxX, upperStartBoxY],
            'middleLeft': [leftStartBoxX, middleStartBoxY],
            'downLeft': [leftStartBoxX, downStartBoxY],
            'middleUpper': [middleStartBoxX, upperStartBoxY],
            'middleDown': [middleStartBoxX, downStartBoxY],
            'upperRight': [RightStartBoxX, upperStartBoxY],
            'middleRight': [RightStartBoxX, middleStartBoxY],
            'downRight': [RightStartBoxX, downStartBoxY]
        }

        for (const [key, value] of Object.entries(allShrinkBoxes)) {
            if (this.checkWithinShrinkBoxes(event, value[0], value[1], boxSize)) {
                // delete box from boxes and rereder again
                checkToShrink = true
                shrinkBoxLocation = key
            }
        }
        return ({checkToShrink, shrinkBoxLocation})
    }

    checkWithinShrinkBoxes = (event, startX, startY, boxSize) => {
        // do i need to draw or create from scratch?
        let rightX = startX + boxSize
        let upperY = startY

        let leftX = startX
        let downY = startY + boxSize

        let coordinateX = event.pageX - this.fgCanvas.offsetLeft
        let coordinateY = event.pageY - this.fgCanvas.offsetTop

        let margin = 2 //px

        if ((coordinateX > leftX - margin) & (coordinateX < rightX + margin) & (coordinateY > upperY - margin) & (coordinateY < downY + margin)) {
            return (true)
        } else {
            return (false)
        }
    }

    onLabelToggleKey = (event) => {
        // -1 as buttosn start with 1 instead of 0
        let keyNumber = Number(event.key) - 1
        console.log('KEY', event.key)

        // if w/s key up down
        // labelObjects.indexOf(this.state.selectedlabel)

        if ([...Array(labelObjects.length).keys()].includes(keyNumber)) {
            this.setState({selectedLabel: labelObjects[keyNumber]})
        } else if (event.key === 'a') {
            // previous
            this.switchPageOnClick(false)
        } else if (event.key === 'd') {
            // next
            this.switchPageOnClick(true)
        } else if (event.key === 'Escape') {
            this.onClickReset()
        } else if (event.key === 'w') {
            let newKey
            if (this.state.selectedLabel == 'undefined') {
                newKey = labelObjects.length - 1
            } else {
                newKey = labelObjects.indexOf(this.state.selectedLabel) - 1
            }
            console.log('newKey', labelObjects, labelObjects[newKey], newKey)
            if (newKey < 0) {
                newKey = labelObjects.length - 1 // starts at 0
            }
            this.setState({selectedLabel: labelObjects[newKey]})
        } else if (event.key === 's') {
            let newKey
            if (this.state.selectedLabel == 'undefined') {
                newKey = 0
            } else {
                newKey = labelObjects.indexOf(this.state.selectedLabel) + 1
            }
            console.log('newKey', labelObjects[newKey])
            if (newKey > labelObjects.length - 1) {
                newKey = 0
            }
            this.setState({selectedLabel: labelObjects[newKey]})
        }
    }

    //// OTHER EVENTS /////
    onLabelToggle = (event) => {
        // Trigger rerender of components affected by Label

        //let peoples = Object.keys({'a':1,'b':2})
        //console.log(peoples.indexOf(this.state.selectedLabel))
        //console.log("event.key", event.key)
        // 'button string': "1 = label"
        this.setState({selectedLabel: event.target.innerHTML.split(" = ")[1]})
        //this.setState({ nextButton: "Next" })
        console.log('label set', event.target.innerHTML)
    }

    onClickInappropriate = () => {
        const inappReason = prompt('Please provide your feedback here.')
        alert('Thank you for submission!')
    }

    onMouseEnter = (event) => {
        // state not needed now, could be used for nice button with color border if selected
        let ClassKey = event.target.innerHTML.replace("- ", "");
        let label = event.target.name;
        let finalKey;

        Object.keys(this.bgBoxes[label]).forEach(key => {
            if ((this.bgBoxes[label][key].ClassKey == ClassKey) & (this.bgBoxes[label][key].deletedAt == "")) {
                finalKey = key
            }

        })

        this.drawMarker(false,
            this.bgBoxes[label][finalKey].rect.startX,
            this.bgBoxes[label][finalKey].rect.startY,
            this.bgBoxes[label][finalKey].rect.w,
            this.bgBoxes[label][finalKey].rect.h,
            this.fgContext, label, finalKey, true)
    }

    onMouseLeave = () => {
        this.resetAllboxes(this.fgContext)
    }

    onClickReset = () => {
        this.resetAllboxes(this.bgContext)
        this.bgBoxes = this.resetCanvasObject()
        this.setState({nextButton: "No Label"})
        this.setState({rerenderBox: true})

    }

    render() {
        return (
            <div className="App">
                {/* <div className = "container"> */}
                <div className="row ">
                    <div className="row h100">
                        <div className="col-10 ">
                            <div className="canvasWrapper marginabove center">
                                <canvas id="bgCanvas" style={{
                                    backgroundImage: `url(${this.imgsrc})`,
                                    backgroundSize: this.canvasWidth + 'px ' + this.canvasHeight + 'px'
                                }}
                                        ref={this.bgCanvasRef} width={this.canvasWidth}
                                        height={this.canvasHeight}></canvas>
                                <canvas id="fgCanvas" ref={this.fgCanvasRef} width={this.canvasWidth}
                                        height={this.canvasHeight}></canvas>
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="row upperbar h70">
                                <div className="marginabove">
                                    <SelectLabelButtons labelObjects={labelObjects}
                                                        selectedLabel={this.state.selectedLabel} onLabelToggle={
                                        this.onLabelToggle} boxObjects={this.bgBoxes} onClickdelete={this.onClickdelete}
                                                        onMouseEnter={this.onMouseEnter}
                                                        onMouseLeave={this.onMouseLeave}
                                                        rerenderBox={this.state.renderBgBox}/>
                                </div>
                            </div>
                            <div className="row lowerbar h30">
                                <div className="marginabove">
                                    <SwitchPage switchPageOnClick={this.switchPageOnClick}
                                                switchPreviousPageOnClick={this.switchPreviousPageOnClick}
                                                nextButton={this.state.nextButton}
                                                blockSwitchPage={this.state.blockSwitchPage}
                                                blockSwitchPageNext={this.state.blockSwitchPageNext}/>
                                    <div className="OtherButtons center">
                                        {/* Feedback button not in MVP
                      <InappropriateContent onClickInappropriate={this.onClickInappropriate} /> */}
                                        <ResetButton onClickReset={this.onClickReset}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className = "row h10">
              Reset all, inappropriate needs to be here as well as next page
          </div> */}
                </div>
                {/* </div> */}
            </div>
        );
    }
}

export default Label;
