import React , { Component } from 'react';
import './App.css';
import Routes from './routes';
import Navigation from './navigation/Navigation.js';
import axios from "axios";

class App extends Component {
  constructor() {
    super()
    this.token = localStorage.getItem("token");
    axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;
    axios.defaults.headers.common["Content-Type"] = `application/json`;
    axios.defaults.headers.post["Content-Type"] = `application/json`;
    axios.defaults.headers.put["Content-Type"] = `application/json`;
    if (this.token) {
      console.log('token',this.token)
      axios.defaults.headers.common["Authorization"] = `Bearer ${this.token}`;
      axios.interceptors.response.use(function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
      }, function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        localStorage.clear();
        window.location.href = '/login';
        return Promise.reject(error);
      });
      this.logged = true
    } else {
      this.logged = false
      console.log('Not logged in.')
    }
  }
  logOut = () =>{
    // resets the token and therefore also this.logged= false
    localStorage.clear();
  }

  render(){
    return (
      <div>
        {/* <Navigation logging = {this.logged} />  */}
        <Navigation logging = {this.logged} logOut = {this.logOut}/>
        <Routes />
      </div>
    );
  }
}

export default App;
