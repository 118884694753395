import './ToggleButtonDelete.css'

const ToggleButtonDelete = ( {label,boxObjectLabel,toggleDelete,onLabelToggle,onClickdelete,onMouseEnter,onMouseLeave} ) => {
    //console.log('deletetooglebutton',boxObjectLabel)
    // TODO: conditional rendering
    if (Object.keys(boxObjectLabel).length === 0){
        return(<p></p>)
    } else {   
        const buttonArrayDelete = Object.keys(boxObjectLabel).map( (i,n) => {
            console.log(i,boxObjectLabel)
            if (boxObjectLabel[i].deletedAt== ""){

                return(<button className={ toggleDelete ? "btn btn-secondary btn-sm" : "btn btn-outline-secondary btn-sm"} onClick={onClickdelete} onMouseEnter= {onMouseEnter} onMouseLeave = {onMouseLeave} name={label}>{"- "+boxObjectLabel[i].ClassKey}</button>)
            }
            }
        ); 
    
        return(
            <div>
                {buttonArrayDelete}
            </div>
        )
    }
}

export default ToggleButtonDelete;