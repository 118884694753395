import './ToggleButton.css'
import ToggleButtonDelete from './ToggleButtonDelete.js'
// create button where you input toggled and color


const ToggleButton = ( {label,boxObjectLabel,toggleLabel,toggleDelete,onLabelToggle,labelObjects,onClickdelete,onMouseEnter,onMouseLeave,number} ) => {
    if (toggleDelete){
        toggleLabel = false
    }
    if (toggleLabel){
        toggleDelete = false
    }
    number += 1
    //console.log('Need to put delete dynamic and toggle dynamic here')
    //console.log('boxObjectLabel',boxObjectLabel)
    return(
        <div>
            <p><button className={ toggleLabel ? "btn btn-primary btn-sm" : "btn btn-outline-primary btn-sm"} onClick={onLabelToggle} >{number + " = " + label}</button>
            <ToggleButtonDelete label = {label} boxObjectLabel = {boxObjectLabel} onClickdelete = {onClickdelete} onMouseEnter= {onMouseEnter} onMouseLeave = {onMouseLeave}/> </p>
        </div>
    )
}

export default ToggleButton;