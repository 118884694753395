import React from "react";
import './Navigation.css'

const Navbar = ({logging,logOut}) => {
    return (
            <div className="navigation">
                <nav className="navbar navbar-expand-sm navbar-dark bg-dark">
                    <a className="navbar-brand" href="/login">
                        <img src="/q2label.png" width="120" height="25" className="d-inline-block align-top" alt="" />
                    </a>
                    <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                        <div className="navbar-nav">
                            {logging ? <a className="nav-item nav-link"  href="/projects">Projects</a> : ''}
                            <a className="nav-item nav-link"  href="/login" onClick = {logOut}>{logging ? "Logout" : "Login"}</a>
                        </div>
                    </div>
                </nav>
            </div>
    )
}
export default Navbar
