import * as React from 'react';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import './Card.css'

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);
// name        = {projectName}
//                           type        = {projects[projectName]['type']}
//                           labels      = {projects[projectName]['labels']}
//                           nImages     = {projects[projectName]['nImages']}
//                           difficulty  = {projects[projectName]['difficulty']}
//                           payoff      = {projects[projectName]['payoff']}
const BasicCard = ({name,type,labels,nImages,difficulty,payoff,iri,startProject}) => {
  return (
    <div className = {'basicCards'}>
    <Card sx={{ minWidth: 275 }} style={{backgroundColor: "white"}}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {type}
        </Typography>
        <Typography variant="h5" component="div">
          {name}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          
          {/* { labels.toString() } */}
        </Typography>
        <Typography variant="body2">
         <i>{ labels.toString() }</i>
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
           
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {difficulty} difficulty
        </Typography>
        <Typography variant="h5" component="div">
          {payoff}
        </Typography>
      </CardContent>
      <CardActions>
        {/* <Link to="/label" > */}
          <Button size="small" onClick={() => {startProject(name)}}> Start Project </Button>
        {/* </Link> */}
      </CardActions>
    </Card>
    </div>
  );
}

export default BasicCard