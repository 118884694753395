import React from 'react';
import { Route, Redirect  } from 'react-router-dom';
 
const RouteGuard = ({ component: Component, ...rest }) => {
 
   function hasJWT() {
       let flag;// = false;
       //let flag;

       // opnieuw checken of nog steeds een token hebt. hier 1 token. Later checkn wat als met refresh token werkt.
       // of cryptowallet?
       /// Project: toevceogen type alsook possible labels
       // Token stuur je in header mee dan kan je pas data ophalen als nog geldig is.
       // na bepaalde tijdseenheid refresh token opvragen. check met elke call api.
       // discussie bart

       //check user has JWT token
       localStorage.getItem("token") ? flag=true : flag=false
        console.log('need to access JWT API',{...rest},'why always true?')

        //flag = true
       return flag
   }

   function selectedProject() {
    let project = true;
    return project
}

    // below, if has Token then execute component with props
   return (
       <Route {...rest}
           render={props => (
               hasJWT() & selectedProject() ?
                 <Component {...props} />
                   :
                   hasJWT() ?
                   <Redirect to={{ pathname: '/projects' }} />
                   :
                   <Redirect to={{ pathname: '/login' }} />
           )}
       />
   );
};
 
export default RouteGuard;